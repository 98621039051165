import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap" }
const _hoisted_2 = { class: "mb-3 mb-md-0 fw-bold" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`notice
        d-flex
        ${_ctx.bg}
        rounded
        border
        border-dashed
        ${_ctx.border}
        ${_ctx.padding}
        ${_ctx.margin}`)
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["svg-icon svg-icon-2tx me-4", `${_ctx.iconClass}`])
        }, [
          _createVNode(_component_inline_svg, { src: _ctx.icon }, null, 8, ["src"])
        ], 2))
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(["svg-icon svg-icon-2tx me-4", `${_ctx.iconClass}`])
        }, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen044.svg" })
        ], 2)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", {
          class: "text-gray-900 fw-bolder",
          innerHTML: _ctx.header
        }, null, 8, _hoisted_3),
        _createElementVNode("div", {
          class: "fs-6 text-gray-700 pe-7",
          innerHTML: _ctx.msg
        }, null, 8, _hoisted_4)
      ])
    ])
  ], 2))
}