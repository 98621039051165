
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "exclamation-notice",
  props: {
    header: { type: String },
    msg: { type: String },
    extClasses: String,
    padding: { type: String, default: "p-6" },
    margin: { type: String, default: "mt-lg-5" },
    bg: { type: String, default: "bg-light-warning" },
    border: { type: String, default: "border-warning" },
    icon: null,
    iconClass: { type: String, default: "svg-icon-warning" },
  },
});
