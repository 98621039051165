
import {useStore} from "vuex";
import {computed, defineComponent, onMounted, ref} from "vue";
import VueNumberAnimation from "vue3-autocounter";

import {ScrollTopComponent} from "@/assets/ts/components/_ScrollTopComponent";
import {Actions} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {useToast} from "vue-toastification";
import ExclamationNotice from "@/components/my-components/notices/exclamation-notice.vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle} from "@/core/helpers/breadcrumb";

export default defineComponent({
    name: "profile",
    components: {
        VueNumberAnimation,
        ExclamationNotice,
    },
    setup() {
        const store = useStore();
        const toast = useToast();

        const currentUser = computed(() => {
            let user = store.getters.currentUser;

            if (user && user.user) {
                store.dispatch(Actions.GET_USER_REQUESTS_DATA, {
                    login: user.user.login,
                    token: JwtService.getToken(),
                });
            }

            return user;
        });

        let in_progress = ref(false);

        const requestsData = computed(() => {
            return store.getters.getUserRequestsData;
        });

        onMounted(() => {
            if (currentUser.value && currentUser.value.user) {
                if (currentUser.value.user.is_disabled) {
                    setCurrentPageBreadcrumbs("Підключення облікового запису!", ["Profile"]);

                    setCurrentPageTitle("Підключення облікового запису!");
                }
            }
        });

        const onEnableAccount = () => {
            Swal.fire({
                text: "Підключити профіль користувача ?",
                title: "Профіль",
                icon: "question",
                buttonsStyling: false,
                confirmButtonText: "Так",
                showCancelButton: true,
                cancelButtonText: "Скасувати",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-success",
                    cancelButton: "btn fw-bold btn-light-danger",
                },
            }).then((value) => {
                if (value.isConfirmed) {
                    in_progress.value = true;

                    store.dispatch(Actions.ENABLE_PROFILE, {
                        token: JwtService.getToken(),
                        login: currentUser.value.user.login,
                        fn: (result) => {
                            result.success ? toast.success(result.message) : toast.error(result.message);

                            in_progress.value = false;
                        },
                    });
                }
            });
        }

        const scrollToElement = (element) => {
            ScrollTopComponent.goToElement(element);
        };

        return {
            currentUser,
            scrollToElement,
            requestsData,

            onEnableAccount,

            in_progress
        };
    },
});
